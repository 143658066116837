import React from "react";
import {
  LineChart as DefaultLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { generateChartColors } from "./utils";

const LineChart = ({ data, keys }) => {
  const colors = generateChartColors(keys.length);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <DefaultLineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        {keys.map((key, index) => (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={colors[index % colors.length]}
          />
        ))}
      </DefaultLineChart>
    </ResponsiveContainer>
  );
};

export { LineChart };
