import { useState, useCallback, useEffect } from 'react';
import * as publishersService from '../services/publishers.service';
import { showErrorToast } from '../../../helpers';

export const usePublishers = (initialFetch = true) => {
    const [publisherData, setPublisherData] = useState([]);
    const [publisherOptions, setPublisherOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchPublishers = useCallback(async () => {
        setLoading(true);
        setError(false);

        try {
            const { publishers } = await publishersService.fetchPublishers();
            setPublisherData(publishers);
            setPublisherOptions(publishers.map(publisher => ({
                value: publisher.publisher_id,
                label: publisher.name
            })));
        } catch (err) {
            setError(true);
            showErrorToast('Failed to fetch publishers');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (initialFetch) {
            fetchPublishers();
        }
    }, [initialFetch, fetchPublishers]);

    return {
        publisherData,
        publisherOptions,
        loading,
        error,
        fetchPublishers
    };
};
