import { useState } from 'react';

export const useModalControl = () => {
  const [modalRecord, setModalRecord] = useState(undefined);

  return {
    modalRecord,
    openCreate: () => setModalRecord(null),
    openEdit: (record) => setModalRecord(record),
    closeModal: () => setModalRecord(undefined)
  };
};
