import React, { useState } from "react";
import {
  MDBContainer,
  MDBTabsPane,
  MDBTabsContent,
  MDBRow,
  MDBCol,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from "mdb-react-ui-kit";
import RecentActivityTab from "./tabs/RecentActivityTab";
import MonthlyTab from "./tabs/MonthlyTab";
import PublisherTab from "./tabs/PublisherTab";
import RequestsTab from "./tabs/RequestsTab";
import SignalsTab from "./tabs/SignalsTab";

const tabs = {
  publisher: "publisher",
  requests: "requests",
  monthly: "monthly",
  recent: "recent",
  signals: "signals",
};

const OnDemandScreening = () => {
  const [currentTab, setCurrentTab] = useState(tabs.publisher);

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol>
          <MDBTabs className="mb-3">
            <MDBTabsItem onClick={() => setCurrentTab(tabs.publisher)}>
              <MDBTabsLink active={currentTab === tabs.publisher}>
                In system
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.requests)}>
              <MDBTabsLink active={currentTab === tabs.requests}>
                Requests
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.signals)}>
              <MDBTabsLink active={currentTab === tabs.signals}>
                Signals
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.monthly)}>
              <MDBTabsLink active={currentTab === tabs.monthly}>
                Chart view
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.recent)}>
              <MDBTabsLink active={currentTab === tabs.recent}>
                Recent activity
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCol>
      </MDBRow>
      <MDBTabsContent>
        <MDBTabsPane show={currentTab === tabs.publisher}>
          <PublisherTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.requests}>
          <RequestsTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.monthly}>
          <MonthlyTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.recent}>
          <RecentActivityTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.signals}>
          <SignalsTab />
        </MDBTabsPane>
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default OnDemandScreening;
