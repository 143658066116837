import { useState, useRef } from 'react';
import { MDBBtn, MDBInputGroup } from 'mdb-react-ui-kit';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const SearchInput = ({ 
  placeholder = '', 
  onSubmit, 
  debounceDelay = 500,
  initialValue = ''
}) => {
  const [value, setValue] = useState(initialValue);
  const lastSubmittedValueRef = useRef(initialValue.trim());
  const debouncedSubmitRef = useRef(
    debounce((inputValue) => {
      const trimmedValue = inputValue.trim();
      if (trimmedValue === lastSubmittedValueRef.current) {
        return;
      }
      lastSubmittedValueRef.current = trimmedValue;
      onSubmit(trimmedValue);
    }, debounceDelay)
  );

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    debouncedSubmitRef.current(newValue);
  };

  const handleSubmit = () => {
    const trimmedValue = value.trim();
    if (trimmedValue === lastSubmittedValueRef.current) {
      return;
    }
    lastSubmittedValueRef.current = trimmedValue;
    onSubmit(trimmedValue);
  };

  return (
    <MDBInputGroup className="p-1">
      <input
        className="form-control"
        placeholder={placeholder}
        type="text"
        onChange={handleChange}
        value={value}
        onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
      />
      <MDBBtn onClick={handleSubmit}>Search</MDBBtn>
    </MDBInputGroup>
  );
};

export default SearchInput;
