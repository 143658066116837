import {
  MDBContainer,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { useState } from "react";
import PublisherProfilesTab from "./tabs/PublisherProfilesTab";
import PublisherDetailsTab from "./tabs/PublisherDetailsTab";
import PublisherUsersTab from "./tabs/PublisherUsersTab";

const tabs = {
  publisher_details: "publisher_details",
  publisher_profiles: "publisher_profiles",
  publisher_users: "publisher_users",
};

const PublisherManagement = () => {
  const [currentTab, setCurrentTab] = useState(tabs.publisher_profiles);

  return (
    <MDBContainer>
      <MDBTabs className="mb-3">
        {/* <MDBTabsItem onClick={() => setCurrentTab(tabs.publisher_details)}>
          <MDBTabsLink
            // className="disabled"
            active={currentTab === tabs.publisher_details}
          >
            Details
          </MDBTabsLink>
        </MDBTabsItem> */}
        <MDBTabsItem onClick={() => setCurrentTab(tabs.publisher_profiles)}>
          <MDBTabsLink active={currentTab === tabs.publisher_profiles}>
            Profiles
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem onClick={() => setCurrentTab(tabs.publisher_users)}>
          <MDBTabsLink active={currentTab === tabs.publisher_users}>
            Users
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
      <MDBTabsContent>
        <MDBTabsPane show={currentTab === tabs.publisher_details}>
          <PublisherDetailsTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.publisher_profiles}>
          <PublisherProfilesTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.publisher_users}>
          <PublisherUsersTab />
        </MDBTabsPane>
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default PublisherManagement;
