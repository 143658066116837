import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn,
} from "mdb-react-ui-kit";
import { logOut } from "../helpers/auth";

const DropdownItem = ({ label, path }) => (
  <MDBDropdownItem>
    <Link to={path} className="dropdown-item">
      {label}
    </Link>
  </MDBDropdownItem>
);

const Navbar = () => {
  const [showBasic, setShowBasic] = useState(false);

  const managementDropdownItems = [
    { label: "Publishers", path: "/publishers" },
    { label: "Users", path: "/users" },
    { label: "Profiles", path: "/profiles" },
    { label: "Keys", path: "/keys" },
    { label: "Cactus settings", path: "/cactus-settings" },
  ];

  const applicationDropdownItems = [{ label: "Watchlist", path: "/watchlist" }];

  const monitoringDropdownItems = [
    { label: "Duplicate submissions", path: "/duplicate-submissions" },
    { label: "On-Demand Screening", path: "/on-demand-screening" },
    { label: "Ambient screening", path: "/ambient-screening" },
    { label: "External API access", path: "/monitor-extapi" },
    { label: "Cognito users", path: "/cognito-users" },
    { label: "Journals", path: "/monitor-journals" },
    { label: "Profiles", path: "/monitor-profiles" },
  ];

  return (
    <MDBNavbar expand="lg" dark bgColor="primary">
      <MDBContainer fluid>
        <MDBNavbarToggler onClick={() => setShowBasic(!showBasic)}>
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag="a" className="nav-link" role="button">
                  Management
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  {managementDropdownItems.map((item) => (
                    <DropdownItem key={item.label} {...item} />
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag="a" className="nav-link" role="button">
                  Applications
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  {applicationDropdownItems.map((item) => (
                    <DropdownItem key={item.label} {...item} />
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag="a" className="nav-link" role="button">
                  Monitoring
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  {monitoringDropdownItems.map((item) => (
                    <DropdownItem key={item.label} {...item} />
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
        <div className="d-flex">
          <MDBBtn onClick={logOut} color="dark" size="md">
            Log Out
          </MDBBtn>
        </div>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default Navbar;
