import React, { useState } from "react";
import {
  MDBContainer,
  MDBTabsPane,
  MDBTabsContent,
  MDBRow,
  MDBCol,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from "mdb-react-ui-kit";
import RecentActivityTab from "./tabs/RecentActivityTab";
import MonthlyTab from "./tabs/MonthlyTab";
import PublisherTab from "./tabs/PublisherTab";
import SubmittedTab from "./tabs/SubmittedTab";
import JournalsTab from "./tabs/JournalsTab";
import SignalsTab from "./tabs/SignalsTab";

const tabs = {
  publisher: "publisher",
  submitted: "submitted",
  monthly: "monthly",
  recent: "recent",
  journals: "journals",
  signals: "signals",
};

const AmbientScreening = () => {
  const [currentTab, setCurrentTab] = useState(tabs.publisher);

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol>
          <MDBTabs className="mb-3">
            <MDBTabsItem onClick={() => setCurrentTab(tabs.publisher)}>
              <MDBTabsLink active={currentTab === tabs.publisher}>
                In system
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.journals)}>
              <MDBTabsLink active={currentTab === tabs.journals}>
                Journals
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.submitted)}>
              <MDBTabsLink active={currentTab === tabs.submitted}>
                Submitted
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.signals)}>
              <MDBTabsLink active={currentTab === tabs.signals}>
                Signals
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.monthly)}>
              <MDBTabsLink active={currentTab === tabs.monthly}>
                Chart view
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.recent)}>
              <MDBTabsLink active={currentTab === tabs.recent}>
                Recent activity
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCol>
      </MDBRow>
      <MDBTabsContent>
        <MDBTabsPane show={currentTab === tabs.publisher}>
          <PublisherTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.submitted}>
          <SubmittedTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.monthly}>
          <MonthlyTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.recent}>
          <RecentActivityTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.journals}>
          <JournalsTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.signals}>
          <SignalsTab />
        </MDBTabsPane>
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default AmbientScreening;
