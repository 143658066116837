import * as XLSX from 'xlsx';

export const exportToExcel = (data, fileName) => {
  const flattenedData = [];

  data.forEach(publisher => {
    publisher.profiles.forEach(profile => {
      const flattenedRow = {
        "Publisher Name": publisher.name,
        "Profile Name": profile.profileName,
        ...profile.components
      };
      flattenedData.push(flattenedRow);
    });
  });

  const worksheet = XLSX.utils.json_to_sheet(flattenedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, fileName);
};
