import * as XLSX from 'xlsx';

export const exportToExcel = (data, fileName) => {
    if (!data || data.length === 0) return;

    const headerMapping = {
        email: 'Email',
        publisherName: 'Publisher Name',
        lastLoginDate: 'Last Login Date',
        lastUpdateDate: 'Last Update Date',
    };

    const wb = XLSX.utils.book_new();

    const modifiedData = data.map(user => {
        const modifiedUser = {};
        for (let key in user) {
            if (headerMapping[key]) {
                modifiedUser[headerMapping[key]] = user[key];
            } else {
                modifiedUser[key] = user[key];
            }
        }
        return modifiedUser;
    });

    const ws = XLSX.utils.json_to_sheet(modifiedData);

    XLSX.utils.book_append_sheet(wb, ws, 'Users');

    XLSX.writeFile(wb, `${fileName}.xlsx`);
};
