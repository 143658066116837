import { axiosInstance } from '../../../helpers/axiosSetup';
import { CACTUS_SETTINGS_API } from './api/cactus-settings.api';

export const fetchEntries = async (offset = 1, limit = Number.MAX_SAFE_INTEGER, search = '') => {
    const { url, method, params } = CACTUS_SETTINGS_API.fetchEntries(offset, limit, search);
    const response = await axiosInstance({ url, method, params });
    return response.data;
};

export const createEntry = async (data) => {
    const { url, method } = CACTUS_SETTINGS_API.createEntry();
    const response = await axiosInstance({ url, method, data });
    return response.data;
};

export const updateEntry = async (entryId, data) => {
    const { url, method } = CACTUS_SETTINGS_API.updateEntry(entryId);
    const response = await axiosInstance({ url, method, data });
    return response.data;
};

export const deleteEntry = async (entryId) => {
    const { url, method } = CACTUS_SETTINGS_API.deleteEntry(entryId);
    const response = await axiosInstance({ url, method });
    return response.data;
};
