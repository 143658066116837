import LoadingOverlay from 'react-loading-overlay';
import Spinner from '../Spinner/Spinner';
LoadingOverlay.propTypes = undefined;

const centerOverlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
};

const SpinnerOverlay = ({ active, children, centerOnScreen = false }) => {
    return (
        <LoadingOverlay
            active={active}
            spinner={<Spinner />}
            fadeSpeed={0}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(0, 0, 0, 0)',
                    ...(centerOnScreen && centerOverlayStyles),
                })
            }}
                >
                { children }
        </LoadingOverlay >
    );
}

export default SpinnerOverlay;
