import React, { useState } from "react";
import {
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import ExportTab from "./tabs/ExportTab";
import RecentActivityTab from "./tabs/RecentActivityTab";

const tabs = {
  export: "export",
  recentActivity: "recentActivity",
};

const ApiAccess = () => {
  const [currentTab, setCurrentTab] = useState(tabs.export);

  return (
    <MDBContainer className="py-5">
      <MDBRow>
        <MDBCol md="12" lg="7">
          <MDBTabs className="mb-3">
            <MDBTabsItem onClick={() => setCurrentTab(tabs.export)}>
              <MDBTabsLink
                active={currentTab === tabs.export}
                icon={<i className="fas fa-file-export" />}
              >
                Export
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.recentActivity)}>
              <MDBTabsLink
                active={currentTab === tabs.recentActivity}
                icon={<i className="fas fa-clock" />}
              >
                Recent activity
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCol>
      </MDBRow>
      <MDBTabsContent>
        <MDBTabsPane show={currentTab === tabs.export}>
          <ExportTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.recentActivity}>
          <RecentActivityTab />
        </MDBTabsPane>
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default ApiAccess;
