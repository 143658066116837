import { useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { publisherTabColumns } from '../columns';
import { getOnDemandScreeningPublishers, showErrorToast } from "../../../helpers";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";

const PublisherTab = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const { data } = await getOnDemandScreeningPublishers();
                setData(data);
            } catch (error) {
                showErrorToast();
            }
            finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [])

    return (
        <SpinnerOverlay active={isLoading}>
            <DataTable
                columns={publisherTabColumns}
                rows={data}
            />
        </SpinnerOverlay>
    )
};

export default PublisherTab;