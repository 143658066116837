import React from "react";
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import Logo from "./components/Logo";
import Navbar from "./components/Navbar";
import Login from "./pages/login/Login";
import ApiAccess from "./pages/api-access/ApiAccess";
import { token } from "./constants";
import WatchListImport from "./pages/watch-list/WatchListImportPage";
import WatchList from "./pages/watch-list/WatchListPage";
import Publishers from "./pages/publishers/Publishers";
import PublisherManagement from "./pages/publisher-management/PublisherManagement";
import { MDBContainer } from "mdb-react-ui-kit";
import Users from "./pages/users/Users";
import Profiles from "./pages/profiles/Profiles";
import Keys from "./pages/keys/Keys";
import CactusSettings from "./pages/cactus-settings/CactusSettings";
import DuplicateSubmissions from "./pages/duplicate-submissions/DuplicateSubmissions";
import OnDemandScreening from "./pages/on-demand-screening/OnDemandScreening";
import CognitoUsers from "./pages/cognito-users/CognitoUsers";
import AmbientScreening from "./pages/ambient-screening/AmbientScreening";
import MonitorProfiles from "./pages/monitor-profiles/MonitorProfiles";
import MonitorJournals from "./pages/monitor-journals/MonitorJournals";

const RequireAuth = ({ children }) => {
  const authorized = localStorage.getItem(token);
  if (authorized) {
    return <MDBContainer className="my-5">{children}</MDBContainer>;
  }
  return <Navigate to="/login" />;
};

const AuthLayout = () => {
  return (
    <>
      <Logo />
      <Outlet />
    </>
  );
};

const MainLayout = () => {
  return (
    <>
      <Logo />
      <Navbar />
      <Outlet />
    </>
  );
};

const EntryPoint = () => {
  let navigate = useNavigate();
  return <Login navigate={navigate} />;
};

const Layout = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<EntryPoint />} />
        <Route path="*" element={<EntryPoint />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route
          path="publisher-management/:id"
          element={
            <RequireAuth>
              <PublisherManagement />
            </RequireAuth>
          }
        />
        <Route
          path="publishers"
          element={
            <RequireAuth>
              <Publishers />
            </RequireAuth>
          }
        />
        <Route
          path="users"
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path="profiles"
          element={
            <RequireAuth>
              <Profiles />
            </RequireAuth>
          }
        />
        <Route
          path="keys"
          element={
            <RequireAuth>
              <Keys />
            </RequireAuth>
          }
        />
        <Route
          path="cactus-settings"
          element={
            <RequireAuth>
              <CactusSettings />
            </RequireAuth>
          }
        />
        <Route
          path="watchlist"
          element={
            <RequireAuth>
              <WatchList />
            </RequireAuth>
          }
        />
        <Route
          path="watchlist-import"
          element={
            <RequireAuth>
              <WatchListImport />
            </RequireAuth>
          }
        />
        <Route
          path="monitor-extapi"
          element={
            <RequireAuth>
              <ApiAccess />
            </RequireAuth>
          }
        />
        <Route
          path="duplicate-submissions"
          element={
            <RequireAuth>
              <DuplicateSubmissions />
            </RequireAuth>
          }
        />
        <Route
          path="on-demand-screening"
          element={
            <RequireAuth>
              <OnDemandScreening />
            </RequireAuth>
          }
        />
        <Route
          path="cognito-users"
          element={
            <RequireAuth>
              <CognitoUsers />
            </RequireAuth>
          }
        />
        <Route
          path="ambient-screening"
          element={
            <RequireAuth>
              <AmbientScreening />
            </RequireAuth>
          }
        />
        <Route
          path="monitor-profiles"
          element={
            <RequireAuth>
              <MonitorProfiles />
            </RequireAuth>
          }
        />
        <Route
          path="monitor-journals"
          element={
            <RequireAuth>
              <MonitorJournals />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  );
};

export default Layout;
