import { MDBSwitch } from "mdb-react-ui-kit";

const SwitchWrapper = ({ label, checked, onChange, name, ...props }) => {
  const createSwitchId = (label, name) => {
    const base = label.toLowerCase().replace(/\s+/g, '-');
    const randomPart = Math.random().toString(36).slice(2, 7);
    return `switch-${base}-${randomPart}`;
  };

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <MDBSwitch
      id={createSwitchId(label)}
      label={label}
      checked={checked}
      onChange={handleChange}
      name={name}
      {...props}
    />
  );
};

export default SwitchWrapper;
