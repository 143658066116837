import { axiosInstance } from "./axiosSetup";

export const getDupSubCorpus = async () => {
  return axiosInstance.get(`/admin/dupsub/corpus`);
};

export const getDupSubRequests = async (timeRange) => {
  return axiosInstance.get(`/admin/dupsub/requests?timeRange=${timeRange}`);
};

export const getDupSubRecent = async (limit = 0, lastEventTimestamp = "") => {
  return axiosInstance.get(
    `/admin/dupsub/recent?limit=${limit}&lastEventTimestamp=${lastEventTimestamp}`
  );
};

export const getDupSubStatisticsPerWeek = () => {
  return axiosInstance.get(`/admin/dupsub/per-week`);
};

export const getDupSubCorpusStatisticsPerWeek = () => {
  return axiosInstance.get(`admin/dupsub/corpus-per-day`);
};

export const getOnDemandScreeningPublishers = async () => {
  return axiosInstance.get(`/admin/ods/publishers`);
};

export const getOnDemandScreeningSubmitted = async (timeRange) => {
  return axiosInstance.get(`/admin/ods/users?timeRange=${timeRange}`);
};

export const getOnDemandScreeningMonthly = async () => {
  return axiosInstance.get(`/admin/ods/monthly`);
};

export const getOnDemandScreeningRecent = async (
  limit = 0,
  lastEventTimestamp
) => {
  return axiosInstance.get(
    `/admin/ods/recent?limit=${limit}&lastEventTimestamp=${lastEventTimestamp}`
  );
};

export const getOnDemandScreeningActionStats = async (timeRange) => {
  return axiosInstance.get(`/admin/ods/action-stats?timeRange=${timeRange}`);
};

export const getOnDemandScreeningStatisticsPerWeek = () => {
  return axiosInstance.get(`/admin/ods/per-week`);
};

export const getAmbientScreeningPublishers = async () => {
  return axiosInstance.get(`/admin/as/publishers`);
};

export const getAmbientScreeningSubmitted = async (timeRange) => {
  return axiosInstance.get(`/admin/as/users?timeRange=${timeRange}`);
};

export const getAmbientScreeningMonthly = async () => {
  return axiosInstance.get(`/admin/as/monthly`);
};

export const getAmbientScreeningRecent = async (
  limit = 0,
  lastEventTimestamp
) => {
  return axiosInstance.get(
    `/admin/as/recent?limit=${limit}&lastEventTimestamp=${lastEventTimestamp}`
  );
};

export const getAmbientScreeningActionStats = async (timeRange) => {
  return axiosInstance.get(`/admin/as/action-stats?timeRange=${timeRange}`);
};

export const getAmbientScreeningJournals = async () => {
  return axiosInstance.get(`/admin/as/per-journal`);
};

export const getOnAmbientScreeningStatisticsPerWeek = () => {
  return axiosInstance.get(`/admin/as/per-week`);
};

export const getApiAccessStatsExportUrl = async (period, publisher_id) => {
  return axiosInstance.get(
    `/admin/access/export?period=${period}&publisher_id=${publisher_id}`
  );
};

export const getApiAccesRecentActivity = async (
  limit = 0,
  lastEventTimestamp
) => {
  return axiosInstance.get(
    `/admin/access/get?period=latest&limit=${limit}&lastEventTimestamp=${lastEventTimestamp}`
  );
};

export const getCognitoUsers = async () => {
  return axiosInstance.get(`/admin/users/monitoring`);
};

export const getDupSubJournals = async () => {
  return axiosInstance.get(`/admin/dupsub/journals`);
};

export const getDupSubMatchesAverage = (timeRange) => {
  return axiosInstance.get(`/admin/dupsub/average?timeRange=${timeRange}`);
};

export const getDupSubMatchesPerPublisher = (timeRange) => {
  return axiosInstance.get(
    `/admin/dupsub/per-publisher?timeRange=${timeRange}`
  );
};

export const getDupSubMatchesPerJournal = (timeRange) => {
  return axiosInstance.get(`/admin/dupsub/per-journal?timeRange=${timeRange}`);
};

export const getPublisherProfilesMonitoring = () => {
  return axiosInstance.get(`/admin/publisher-profile/list`);
};
