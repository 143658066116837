import { MDBRow, MDBTypography } from "mdb-react-ui-kit";

const FeatureSection = ({ 
  title, 
  visible = true, 
  children,
  className = "mb-3"
}) => {
  if (!visible) return null;
  
  return (
    <MDBRow className={className}>
      <hr className="hr" />
      <MDBTypography variant="h6" className="mb-3 fw-bold">
        {title}
      </MDBTypography>
      {children}
    </MDBRow>
  );
};

export default FeatureSection;
