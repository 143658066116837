import { useState, useEffect } from 'react';
import { itemsPerPage } from '../../../constants';
import { getProfiles, showErrorToast } from '../../../helpers';

const useProfiles = (currentPage) => {
    const [profilesData, setProfilesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = async () => {
        setLoading(true);
        setError(false);
        try {
            const { data } = await getProfiles(
                currentPage * itemsPerPage + 1,
                itemsPerPage,
                searchTerm
            );

            const formattedData = data?.profiles?.map((profile) => ({
                ...profile,
                id: profile.profile_id,
                name: profile.profile_name,
            }));
            setProfilesData(formattedData);
            setPageCount(Math.ceil(data.total / itemsPerPage));
        } catch (err) {
            showErrorToast();
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    return { profilesData, loading, error, pageCount, searchTerm, setSearchTerm, fetchData };
};

export default useProfiles;
