import React from "react";


const TextInput = props => {
    return (
        <div className="mb-3">
            <label className="grey-text">{props.label}</label>
            <input {...props} {...props.field} className="form-control" />
        </div>
    );
};

export default TextInput;