import * as XLSX from "xlsx";

export const exportToExcel = (data, fileName) => {
  const flattenedData = [];

  let currentPublisher = null;

  data.forEach((item) => {
    if (item.publisher) {
      currentPublisher = item.publisher;
      const flattenedRow = {
        "Publisher": item.publisher,
        "Journal": "",
        "Count": item.count
      };
      flattenedData.push(flattenedRow);
    } else {
      const flattenedRow = {
        "Publisher": currentPublisher,
        "Journal": item.journalName,
        "Count": ""
      };
      flattenedData.push(flattenedRow);
    }
  });

  const worksheet = XLSX.utils.json_to_sheet(flattenedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
