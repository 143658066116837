export const recentActivityColumns = [
    {
        label: 'Timestamp',
        field: 'timestamp',
        width: 160,
    },
    {
        label: 'Publisher name',
        field: 'publisherName',
        width: 150,
    },
    {
        label: 'Application',
        field: 'application',
    },
    {
        label: 'Service type',
        field: 'serviceType',
    },
    {
        label: 'Manuscript',
        field: 'manuscript',
    },
    {
        label: 'Parameters',
        field: 'parameters',
    },
    {
        label: 'Status',
        field: 'statusCode',
    }
];