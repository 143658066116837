import { useMemo } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBTooltip,
} from 'mdb-react-ui-kit';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import SpinnerOverlay from '../../components/SpinnerOverlay/SpinnerOverlay';
import DataTable from '../../components/table/DataTable';
import Pagination from '../../components/Pagination/Pagination';
import NoContentMessage from '../../components/NoContentMessage/NoContentMessage';
import SearchInput from '../../components/SearchInput/SearchInputDebounced';
import { default as ConfirmDialog } from '../../components/Dialog/Dialog';
import CactusSettingsModal from './components/CactusSettingsModal';
import { useCactusSettings, usePublishers, useModalControl, useDialogControl } from './hooks';
import { columns } from './columns';

const CactusSettings = () => {
  const {
    settings,
    loading: cactusLoading,
    error: cactusError,
    pageCount,
    currentPage,
    setCurrentPage,
    setSearchTerm,
    fetchEntries,
    deleteEntry,
  } = useCactusSettings();

  const handlePageClick = ({ selected = 0 }) => {
    setCurrentPage(selected);
  };

  const {
    publisherOptions,
    loading: publishersLoading,
    error: publishersError,
  } = usePublishers();

  const {
    modalRecord,
    openCreate,
    openEdit,
    closeModal
  } = useModalControl();

  const handleDelete = async (id) => {
    await deleteEntry(id);
    await fetchEntries();
  }

  const {
    isDialogOpen,
    openDialog,
    closeDialog,
    handleConfirm
  } = useDialogControl(handleDelete);

  const dataWithActions = useMemo(() => settings?.map((entry, index) => ({
    ...entry,
    editAction:
      <div key={index} onClick={(e) => e.stopPropagation()}>
        <MDBTooltip tag={'div'} title='Edit Key' placement='top'>
          <MDBBtn
            color='secondary'
            floating
            onClick={() => openEdit(settings[index])}
          >
            <MDBIcon fas icon='edit' />
          </MDBBtn>
        </MDBTooltip>
      </div>,
    deleteAction:
      <div key={index} onClick={e => e.stopPropagation()} >
        <MDBTooltip tag={'div'} title='Remove Key' placement='top'>
          <MDBBtn
            color='danger'
            floating
            onClick={() => openDialog(entry.id)}
          >
            <MDBIcon far icon='trash-alt' />
          </MDBBtn>
        </MDBTooltip>
      </div>
  })), [settings]);

  const isLoading = cactusLoading || publishersLoading;
  const hasError = cactusError || publishersError;

  return (
    <SpinnerOverlay active={isLoading} centerOnScreen={true}>
      <CactusSettingsModal
        record={modalRecord}
        publisherOptions={publisherOptions}
        onClose={closeModal}
        onSubmitSuccess={fetchEntries}
      />
      <ConfirmDialog
        isOpen={isDialogOpen}
        setIsOpen={closeDialog}
        onConfirm={handleConfirm}
        dialogText="Are you sure you want to delete this entry?"
        showSpinner={false}
      />
      <MDBRow className='mb-2'>
        <MDBCol sm={8} md={6} lg={4}>
          <SearchInput
            placeholder='Search by setting name...'
            onSubmit={setSearchTerm}
          />
        </MDBCol>
        <MDBCol className='text-end'>
          <MDBBtn
            color='primary'
            size='md'
            onClick={openCreate}
            disabled={isLoading}
          >
            <MDBIcon icon='plus-circle' className='me-2' />
            New setting
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      {hasError && <ErrorMessage />}
      {!hasError && dataWithActions && (
        <>
          <DataTable rows={dataWithActions} columns={columns} />
          <MDBRow>
            <MDBCol className='mt-2'>
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            </MDBCol>
          </MDBRow>
        </>
      )}
      {!hasError && !isLoading && !dataWithActions.length && <NoContentMessage />}
    </SpinnerOverlay>
  )
};

export default CactusSettings;
