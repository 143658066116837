import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import { withFormik } from 'formik';
import Loader from './Loader';
import QR from './QR';
import { MFASignIn, logIn } from '../helpers/auth';
import { filterObj, showSuccessToast, showErrorToast } from '../helpers/helpers';
import { linkedDeviceMessage, notificationsTime } from '../constants';
import './LoginForm.css';

const UserDeviceForm = ({ authResponse, handleSubmit, isSubmitting, setValues, setFieldValue, status, setTab }) => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        setUserData(authResponse);
    }, [authResponse]);

    useEffect(() => {
        if (status?.deviceLinked) {
            showSuccessToast(linkedDeviceMessage);
            setTimeout(() => {
                setTab('login');
            }, notificationsTime);
        };
        if (status?.wrongMfaCode) {
            setTab('login');
        };
    }, [status, setTab]);

    useEffect(() => {
        if (userData) {
            setValues({
                usercode: '',
                session: userData.session,
                username: userData.username,
                mfa: userData.mfa,
                AccessToken: userData.AccessToken,
            });
        }
    }, [userData, setValues]);

    const btnValue = (userData?.mfa) ? 'Login' : 'Link';
    const handleCodeChange = (e) => setFieldValue('usercode', e.target.value)

    return (
        <div className='main-section'>
            <MDBContainer className='form_wrapper d-flex justify-content-center'>
                {isSubmitting || !userData
                    ? <Loader />
                    : <form className='col-10 col-md-6' onSubmit={handleSubmit}>
                        {!userData.mfa &&
                            <>
                                <div className='font-weight-bolder mb-3'>
                                    <p>Add your TOTP authenticator to your account. You can either add a code manually or scan a QR-code to link your MFA device.</p>
                                    <p>Enter the resulting 6-figures code and click 'LINK'</p>
                                </div>
                                <QR value={userData.qr} />
                                <p className='font-weight-bolder my-3 user_code'>Your code: {userData.code}</p>
                            </>
                        }
                        <MDBInput
                            onChange={handleCodeChange}
                            name='usercode'
                            label='Code'
                            type='text'
                            autoFocus
                        />

                        <div className='text-center mt-4'>
                            <MDBBtn color='primary' type='submit'>{btnValue}</MDBBtn>
                        </div>
                    </form>
                }
            </MDBContainer>
        </div>
    );
};

const mapPropsToValues = props => ({
    usercode: '',
    session: props.session,
    username: props.username,
    mfa: props.mfa,
    AccessToken: props.AccessToken,
});

const handleSubmit = async (value, { setStatus, props }) => {
    const payload = filterObj(value, 'mfa');
    return await MFASignIn(value.mfa ? 'mfaverify' : 'mfasetup', payload)
        .then(response => {
            value.mfa
                ? logIn(response, props)
                : setStatus({ deviceLinked: true });
        })
        .catch(error => {
            showErrorToast(error)
            if (value.mfa && error) {
                setStatus({ wrongMfaCode: true, error: error });
            }
        });
};


export default withFormik({ handleSubmit, mapPropsToValues })(UserDeviceForm);
