import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MDBCheckbox, MDBCol, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { FormModalWrapper } from '../../../../components';
import {
  createUser,
  getProfilesByPublisher,
  showErrorToast,
  showSuccessToast,
} from '../../../../helpers';
import { PASSWORD_SETUP_OPTIONS, CREATE_USER_PASSWORD_SETUP_OPTIONS, regExpPassword } from '../../../../constants';

const PublisherUserCreateModal = ({ onClose, isOpen, onSuccessSubmit }) => {
  const { id: publisherId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [profileOptions, setProfileOptions] = useState(null);

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profilesListResponse = await getProfilesByPublisher(publisherId);
        const remapedProfileOptions = profilesListResponse.data.rows.map(
          (x) => ({
            label: x.profile_name,
            value: x.profile_id,
          })
        );
        setProfileOptions(remapedProfileOptions ?? []);
      } catch (error) {
        showErrorToast();
        handleCloseModal();
      } finally {
        setIsLoading(false);
      }
    };

    if (!!isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const formik = useFormik({
    initialValues: {
      email: '',
      profile_id: '',
      isAdminGroup: false,
      customPassword: '',
      publisherSupervisoryRole: false,
      passwordSetupType: PASSWORD_SETUP_OPTIONS.SYSTEM_GENERATED
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Email is required'),
      profile_id: Yup.number().required('Profile is required'),
      passwordSetupType: Yup.number().required(),
      customPassword: Yup.string()
        .when('passwordSetupType', {
          is: PASSWORD_SETUP_OPTIONS.CUSTOM,
          then: schema => schema
            .matches(regExpPassword, 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character, and be at least 8 characters long')
            .required('Password is required'),
          otherwise: schema => schema.notRequired(),
        }),
    }),
    onSubmit: async (values) => {
      try {
        const userToCreate = {
          email: values.email?.toLowerCase(),
          profile_id: values.profile_id,
          is_admin_group: values.isAdminGroup,
          permissions: {
            publisherSupervisoryRole: values.publisherSupervisoryRole,
          },
          passwordSetupType: values.passwordSetupType
        };

        if (values.passwordSetupType === PASSWORD_SETUP_OPTIONS.CUSTOM) {
          userToCreate.password = values.customPassword;
        }

        await createUser(userToCreate);
        showSuccessToast();
        handleCloseModal();
        await onSuccessSubmit();
      } catch (error) {
        showErrorToast(error);
      }
    },
  });

  const handleProfileChange = (e) => {
    formik.setFieldValue('profile_id', e.value);
  };

  const handlePasswordTypeChange = (e) => {
    if (e.value === PASSWORD_SETUP_OPTIONS.SYSTEM_GENERATED) {
      formik.setFieldValue('customPassword', '')
    }
    formik.setFieldValue('passwordSetupType', e.value)
  }

  const showCustomPasswordField = formik?.values.passwordSetupType === PASSWORD_SETUP_OPTIONS.CUSTOM

  return (
    <FormModalWrapper
      title={'Create user'}
      isOpen={isOpen}
      isLoading={isLoading || formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow>
        <MDBCol md={6} style={{ marginTop: '27px' }}>
          <MDBInput
            label="Email"
            id="email"
            type="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <label className="grey-text">Profile</label>
          <Select
            {...formik.getFieldProps('profile_id')}
            id="profile_id"
            placeholder="Select profile"
            options={profileOptions}
            value={
              formik.values?.profile_id
                ? profileOptions.find(
                  (element) => element.value === formik.values.profile_id
                )
                : null
            }
            onChange={handleProfileChange}
            onBlur={() => formik.setFieldTouched('profile_id')}
            isSearchable={true}
          />
          {formik.touched.profile_id && formik.errors.profile_id ? (
            <div className="text-danger">{formik.errors.profile_id}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol>
          <MDBCheckbox
            id="isAdminGroup"
            name="isAdminGroup"
            label="Is Admin"
            checked={formik.values.isAdminGroup}
            onChange={() =>
              formik.setFieldValue('isAdminGroup', !formik.values.isAdminGroup)
            }
            onBlur={formik.handleBlur}
          />
        </MDBCol>
        <MDBCol>
          <Select
            {...formik.getFieldProps('passwordSetupType')}
            id="passwordSetupType"
            placeholder='Select password setup'
            options={CREATE_USER_PASSWORD_SETUP_OPTIONS}
            value={CREATE_USER_PASSWORD_SETUP_OPTIONS.find(element => element.value === formik.values.passwordSetupType)}
            onChange={handlePasswordTypeChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol>
          <MDBCheckbox
            id="publisherSupervisoryRole"
            name="publisherSupervisoryRole"
            label="Is Publisher Admin"
            checked={formik.values?.publisherSupervisoryRole}
            onChange={() =>
              formik.setFieldValue(
                'publisherSupervisoryRole',
                !formik.values.publisherSupervisoryRole
              )
            }
            onBlur={formik.handleBlur}
          />
        </MDBCol>
        <MDBCol>
          {showCustomPasswordField && (
            <>
              <MDBInput
                label="Password"
                id="customPassword"
                type="password"
                {...formik?.getFieldProps('customPassword')}
                autoComplete="new-password"
              />
              {formik?.errors?.customPassword ? (
                <div className="text-danger">{formik?.errors?.customPassword}</div>
              ) : null}
            </>
          )}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper>
  );
};

export default PublisherUserCreateModal;
