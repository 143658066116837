import { useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import { getDupSubJournals, showErrorToast } from "../../../helpers";
import { journalsColumns } from "../columns";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { exportToExcel } from "../export";

const JournalsTab = () => {
  const [tableData, setTableData] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const mapResponseData = (response) => {
    const output = [];

    console.log("response", response);

    response?.journals?.forEach((item) => {
      output.push({
        publisher: item.publisher,
        journalsCount: item.count,
      });

      item?.journals?.forEach((journal) => {
        output.push({
          journalName: journal.journal,
          submissionsCount: journal.count,
        });
      });
    });

    return output;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getDupSubJournals();
        const mappedData = mapResponseData(data);
        setCardData({
          totalJournals: data?.totalJournals ?? 0,
          totalPublishers: data?.totalPublishers ?? 0,
        });
        setTableData(mappedData);
      } catch (error) {
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleExport = () => {
    exportToExcel(tableData, "Journals");
  };

  return (
    <SpinnerOverlay active={isLoading}>
      <div className="d-flex justify-content-end mb-3">
        <MDBBtn
          disabled={isLoading}
          color="primary"
          className="px-4"
          onClick={handleExport}
        >
          Export
        </MDBBtn>
      </div>
      <MDBCard className="mb-3">
        <MDBCardHeader>
          <MDBCardTitle className="text-center mt-2">Statistics</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBCardText>
            <strong>Total # publishers: {cardData?.totalPublishers}</strong>
          </MDBCardText>
          <MDBCardText>
            <strong>Total # journals: {cardData?.totalJournals}</strong>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
      <DataTable columns={journalsColumns} rows={tableData} />
    </SpinnerOverlay>
  );
};

export default JournalsTab;
