export const journalsListColumns = [
  {
    label: "Publisher",
    field: "publisher",
  },
  {
    label: "Journals count",
    field: "journalsCount",
  },
  {
    label: "Journal name",
    field: "journalName",
  },
  {
    label: "Publisher journal ID",
    field: "publisherJournalId",
  },
  {
    label: "Subject",
    field: "subject",
  },
];
export const journalSuspectsColumns = [
  {
    label: "ID",
    field: "id",
  },
  {
    label: "Subject",
    field: "subject",
  },
  {
    label: "Journal count",
    field: "journalsCount",
  },
];
