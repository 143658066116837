import { token } from "../constants";
import { axiosInstance } from "./axiosSetup";

export const authorize = async (credentials) => {
  return axiosInstance
    .post('admin/authorize', { ...credentials, mfaSetupRequired: true });
};

export const MFASignIn = (stage, payload) => axiosInstance.post(stage, payload);

export const logIn = (response, props) => {
  localStorage.setItem(token, response.data.token);
  localStorage.setItem('username', response.data.username);
  props.navigate('/publishers');
};

export const logOut = () => {
  localStorage.removeItem(token);
  window.location.reload();
};