import { axiosInstance } from "./axiosSetup";

export const getPublishers = (startrow = 1, maxrows = 50, search = '') => {
    const searchQuery = search.length > 0 ? `&name=${search}` : ''
    return axiosInstance.get(
        `/admin/publisher/list?start_row=${startrow}&max_rows=${maxrows}${searchQuery}`
    );
};

export const getProfilesByPublisher = (publisherId) => {
    return axiosInstance.get(
        `/admin/publisher/profiles/${publisherId}`
    );
};

export const getUsersByPublisher = (publisherId) => {
    return axiosInstance.get(
        `/admin/publisher/users/${publisherId}`
    );
};

export const getPublisher = (id) => {
    return axiosInstance.get(`/admin/publisher/extract/${id}`)
}

export const createPublisher = (data) => {
    return axiosInstance.post(`/admin/publisher/create`, data)
}

export const updatePublisher = (id, data) => {
    return axiosInstance.patch(`/admin/publisher/${id}`, data);
}
