import { useState, useEffect } from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';
import { itemsPerPage } from '../../../constants';
import { getUsers, showErrorToast } from '../../../helpers';

const useUsers = (currentPage) => {
    const [usersData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = async () => {
        setLoading(true);
        setError(false);
        try {
            const { data } = await getUsers(
                currentPage * itemsPerPage + 1,
                itemsPerPage,
                searchTerm
            );

            const formattedData = data?.users?.map((user) => ({
                ...user,
                administratorsGroup: user.administratorsGroup ? <MDBIcon color="success" icon="check" size="lg" /> : null,
            }));
            setUsersData(formattedData);
            setPageCount(Math.ceil(data.total / itemsPerPage));
        } catch (err) {
            showErrorToast();
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    return { usersData, loading, error, pageCount, searchTerm, setSearchTerm, fetchData };
};

export default useUsers;
