import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBBtn, MDBIcon, MDBTooltip } from 'mdb-react-ui-kit';
import { itemsPerPage } from '../../../constants';
import { getPublishers } from '../../../helpers/publishers';
import { showErrorToast } from '../../../helpers';

const usePublishers = (currentPage) => {
    const navigate = useNavigate();
    const [publishersData, setPublishersData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');

    const handleDetailedPublisherViewAction = useCallback((publisherId) => {
        navigate(`/publisher-management/${publisherId}`);
    }, [navigate]);

    const fetchData = async () => {
        setLoading(true);
        setError(false);
        try {
            const startRow = currentPage * itemsPerPage + 1;
            const { data } = await getPublishers(startRow, itemsPerPage, searchTerm);

            const formattedData = data.publishers.map((publisher) => ({
                ...publisher,
                clickEvent: () => handleDetailedPublisherViewAction(publisher.publisher_id),
                detailedViewAction: (
                    <div key={publisher.publisher_id} onClick={(e) => e.stopPropagation()}>
                        <MDBTooltip tag="div" title="Publisher Management" placement="top">
                            <MDBBtn color="info" floating onClick={() => handleDetailedPublisherViewAction(publisher.publisher_id)}>
                                <MDBIcon fas icon="cogs" />
                            </MDBBtn>
                        </MDBTooltip>
                    </div>
                ),
            }));

            setPublishersData(formattedData);
            setPageCount(Math.ceil(data.total / itemsPerPage));
        } catch (err) {
            showErrorToast();
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    return { publishersData, loading, error, pageCount, searchTerm, setSearchTerm, fetchData };
};

export default usePublishers;
