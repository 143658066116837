import ReactSelect from "react-select";

export const timeRangeOptions = {
    last_7_days: { value: 'last_7_days', label: 'Last 7 days' },
    this_month: { value: 'this_month', label: 'This month' },
    last_month: { value: 'last_month', label: 'Last month' },
    year_to_date: { value: 'year_to_date', label: 'Year to date' }
};

const arrayTimeRangeOptions = Object.keys(timeRangeOptions).map(key => ({
    value: timeRangeOptions[key].value,
    label: timeRangeOptions[key].label
}));

const TimeRangeSelect = ({ setTimeRangeOption }) => {
    return (
        <ReactSelect
            options={arrayTimeRangeOptions}
            defaultValue={timeRangeOptions.last_7_days}
            onChange={(selectedOption) => setTimeRangeOption(selectedOption.value)}
        />
    )
}

export { TimeRangeSelect }