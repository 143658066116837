import React, { useState, useEffect } from 'react';
import { MDBBtn, MDBRadio, MDBTypography, MDBCol } from 'mdb-react-ui-kit';
import Select from 'react-select';
import SpinnerOverlay from '../../../components/SpinnerOverlay/SpinnerOverlay';
import { showErrorToast, showSuccessToast, getPublishers, getApiAccessStatsExportUrl } from '../../../helpers';

const selectStyles = {
    menu: provided => ({ ...provided, zIndex: Number.MAX_SAFE_INTEGER })
};

const ExportTab = () => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [publisherId, setPublisherId] = useState(null);
    const [period, setPeriod] = useState(1);

    const radioChangeHandler = (e) => {
        setPeriod(+e.target.value);
    };

    const selectChangeHandler = (e) => {
        setPublisherId(e.value);
    };

    const exportHandler = async () => {
        try {
            setLoading(true);
            const { data: url } = await getApiAccessStatsExportUrl(period, publisherId);

            const a = document.createElement('a');
            a.href = url;
            document.body.appendChild(a);
            a.click();
            a.remove();
            showSuccessToast();
        } catch (error) {
            showErrorToast();
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const processPublisherOptions = (data = []) => (
        data.map(x => ({
            label: x.name,
            value: x.publisher_id
        })).sort((a, b) => a.label.localeCompare(b.label))
    );

    const fetchPublishers = async () => {
        try {
            const response = await getPublishers();
            return processPublisherOptions(response?.data?.publishers);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            const fetchedPublisherOptions = await fetchPublishers();
            setOptions(fetchedPublisherOptions);
            setLoading(false);
        };

        loadData();
    }, []);

    return (
        <SpinnerOverlay active={loading}>
            <MDBCol center md={6} className='center'>
                <MDBTypography className='mt-2' variant='h5'>Export external API access data</MDBTypography>
                <hr className='hr' />
                <div className='mb-4'>
                    <label className='form-label'>Publisher</label>
                    <Select
                        placeholder='Select publisher'
                        options={options}
                        value={options?.find(element => element.value === publisherId)}
                        onChange={selectChangeHandler}
                        isSearchable={true}
                        styles={selectStyles}
                    />
                </div>
                <label className='form-label'>Duration</label>
                <div className='mb-4 d-flex flex-column'>
                    <MDBRadio name='period' id='period1' value={1} checked={period === 1} onChange={radioChangeHandler} label='Current Month (till date)' />
                    <MDBRadio name='period' id='period2' value={2} checked={period === 2} onChange={radioChangeHandler} label='Last Month' />
                    <MDBRadio name='period' id='period3' value={3} checked={period === 3} onChange={radioChangeHandler} label='Two Months Ago' />
                </div>
                <div className='text-center'>
                    <MDBBtn
                        color='primary'
                        disabled={!publisherId || loading}
                        onClick={exportHandler}
                    >
                        Export
                    </MDBBtn>
                </div>
            </MDBCol>
        </SpinnerOverlay>
    );
}

export default ExportTab;