import React from "react";
import Layout from "./Layout";
import Footer from "./components/Footer";
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css"

const App = () => {
  return (
    <ThemeProvider theme={{}}>
      <ToastContainer />
      <Layout />
      <Footer />
    </ThemeProvider>
  );
};


export default App;