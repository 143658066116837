import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import SuspectsTypeSelect from '../components/SuspectsTypeSelect';
import Pagination from '../../../components/Pagination/Pagination';
import DataTable from '../../../components/table/DataTable';
import { adminTabColumns } from '../columns';
import { useNavigate } from 'react-router-dom';
import { itemsPerPage } from '../../../constants';
import { getWatchlist } from '../../../helpers';
import NoContentMessage from '../../../components/NoContentMessage/NoContentMessage';
import SpinnerOverlay from '../../../components/SpinnerOverlay/SpinnerOverlay';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { ExportModal } from '../components/ExportModal';

const WatchListTab = () => {
  const navigate = useNavigate();

  const [suspectsType, setSuspectsType] = useState(1);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const [watchlistData, setWatchlistData] = useState(null);

  const handlePageClick = (event) => {
    const newCurrentPage = event.selected;
    setCurrentPage(newCurrentPage);

    const newStartRow = newCurrentPage * itemsPerPage + 1;
    fetchWatchlist(suspectsType, newStartRow);
  };

  const handleSuspectTypeChange = (event) => {
    const newSuspectType = event.value;
    setSuspectsType(newSuspectType);
    setCurrentPage(0);
    fetchWatchlist(newSuspectType, 1);
  };

  const fetchWatchlist = async (suspectsType, startrow) => {
    setLoading(true);
    try {
      const res = await getWatchlist(suspectsType, startrow, itemsPerPage);
      const { suspects: data, total } = res.data;
      setWatchlistData(data);
      setPageCount(Math.ceil(total / itemsPerPage));
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWatchlist(suspectsType, 1);
  }, []);

  const handleImportNavigate = () => {
    navigate('/watchlist-import', {
      state: { suspectsType: suspectsType },
    });
  };

  return (
    <div>
      <ExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        suspectsType={suspectsType}
      />
      <MDBRow>
        <MDBCol className="text-end">
          <MDBBtn
            className="mb-4 me-3"
            color="secondary"
            size="md"
            onClick={handleImportNavigate}
            disabled={loading}
          >
            <MDBIcon icon="fas fa-file-import" className="me-2" /> Import
          </MDBBtn>
          <MDBBtn
            className="mb-4"
            color="secondary"
            size="md"
            onClick={() => setIsExportModalOpen(true)}
          >
            <MDBIcon icon="fas fa-file-export" className="me-2" /> Export
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <div className="table_header table-primary d-flex px-3 py-2">
        <MDBCol sm={8} md={6} lg={4}>
          <SuspectsTypeSelect
            suspectsType={suspectsType}
            onSuspectTypeChange={handleSuspectTypeChange}
          />
        </MDBCol>
      </div>
      <SpinnerOverlay active={loading}>
        {watchlistData?.length > 0 && (
          <>
            <DataTable columns={adminTabColumns} rows={watchlistData} />
            <MDBRow>
              <MDBCol className="mt-2">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageCount={pageCount}
                  currentPage={currentPage}
                />
              </MDBCol>
            </MDBRow>
          </>
        )}
        {error && <ErrorMessage />}
        {watchlistData?.length === 0 && (
          <NoContentMessage message={'No data'} />
        )}
      </SpinnerOverlay>
    </div>
  );
};

export default WatchListTab;
