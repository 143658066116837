import { useEffect, useState } from "react";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import DataTable from "../../components/table/DataTable";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import {
  convertISOToReadableDate,
  getCognitoUsers,
  showErrorToast,
} from "../../helpers";
import { cognitoUsersColumns } from "./columns";
import { exportToExcel } from "./export";

const CognitoUsers = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getCognitoUsers();
        const mappedData = data?.users?.map((x) => {
          return {
            ...x,
            lastLoginDate: convertISOToReadableDate(x.lastLoginDate),
            lastUpdateDate: convertISOToReadableDate(x.lastUpdateDate),
          };
        });
        setData(mappedData);
      } catch (error) {
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleExport = () => {
    exportToExcel(data, "CognitoUsers");
  };

  return (
    <MDBContainer fluid>
      <SpinnerOverlay active={isLoading}>
        <div className="d-flex justify-content-end mb-3">
          <MDBBtn
            disabled={isLoading}
            color="primary"
            className="px-4"
            onClick={handleExport}
          >
            Export
          </MDBBtn>
        </div>
        <DataTable
          columns={cognitoUsersColumns}
          rows={data}
          enableSorting
          defaultSortDirection="dsc"
          defaultSortField="lastLoginDate"
        />
      </SpinnerOverlay>
    </MDBContainer>
  );
};

export default CognitoUsers;
