import React from 'react';
import {
  MDBInput,
  MDBCol,
  MDBRow,
  MDBCheckbox,
  MDBTypography,
} from 'mdb-react-ui-kit';
import Select from 'react-select';
import { PASSWORD_SETUP_OPTIONS, CREATE_USER_PASSWORD_SETUP_OPTIONS } from '../../../constants';

const PublisherUserForm = ({
  values,
  onChange,
  errors,
  getFieldProps,
  handleBlur,
}) => {


  const handlePasswordSetupChange = (e) => {
    if (e.value === PASSWORD_SETUP_OPTIONS.CUSTOM) {
      onChange('customPassword', '');
    }
    onChange('passwordSetupType', e.value);
  };

  const showCustomPasswordField = values.passwordSetupType === PASSWORD_SETUP_OPTIONS.CUSTOM;
  return (
    <>
      <MDBRow>
        <MDBTypography variant="h5" className="mb-3">
          User
        </MDBTypography>
        <MDBCol md={6}>
          <MDBInput
            label="Email"
            id="email"
            type="email"
            {...getFieldProps('email')}
          />
          {errors?.email ? (
            <div className="text-danger">{errors?.email}</div>
          ) : null}
        </MDBCol>
        <MDBCol>
          <Select
            {...getFieldProps('passwordSetupType')}
            id="passwordSetupType"
            placeholder='Select password setup'
            options={CREATE_USER_PASSWORD_SETUP_OPTIONS}
            value={CREATE_USER_PASSWORD_SETUP_OPTIONS.find(element => element.value === values.passwordSetupType)}
            onChange={handlePasswordSetupChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol>
          <MDBCheckbox
            id="isAdminGroup"
            name="isAdminGroup"
            label="Is Admin"
            checked={values.isAdminGroup}
            onChange={() => onChange('isAdminGroup', !values.isAdminGroup)}
            onBlur={handleBlur}
          />
        </MDBCol>
        <MDBCol>
          {showCustomPasswordField && (
            <>
              <MDBInput
                label="Password"
                id="customPassword"
                type="password"
                {...getFieldProps('customPassword')}
                autoComplete="new-password"
              />
              {errors?.customPassword ? (
                <div className="text-danger">{errors?.customPassword}</div>
              ) : null}
            </>
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol>
          <MDBCheckbox
            id="publisherSupervisoryRole"
            name="publisherSupervisoryRole"
            label="Is Publisher Admin"
            checked={values?.publisherSupervisoryRole}
            onChange={() =>
              onChange(
                'publisherSupervisoryRole',
                !values.publisherSupervisoryRole
              )
            }
            onBlur={handleBlur}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default PublisherUserForm;
