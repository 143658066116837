import React from "react";
import logoSTM from "../assets/img/logo-stm.png"

const Logo = () => {
  return (
    <div className="bg-white text-primary">
      <img src={logoSTM} alt="" style={{ width: '14rem', margin: '0.5rem 1rem' }} />
      <div className="d-inline-block align-middle">
        <h4 className="mb-0">STM Integrity Hub Administrator</h4>
      </div>
    </div>
  );
};

export default Logo;