import React from "react";
import Select from "react-select";
import { suspectTypeSelectOptions } from "../../../constants";

const SuspectsTypeSelect = ({
  suspectsType,
  onSuspectTypeChange,
  isDisabled = false,
}) => {
  return (
    <Select
      isDisabled={isDisabled}
      options={suspectTypeSelectOptions}
      onChange={onSuspectTypeChange}
      value={suspectTypeSelectOptions.find(
        (item) => item.value === suspectsType
      )}
      isSearchable={false}
    />
  );
};

export default SuspectsTypeSelect;
