import React, { useEffect, useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBRow,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
} from "mdb-react-ui-kit";
import { TimeRangeSelect, timeRangeOptions } from "../../../components";
import DataTable from "../../../components/table/DataTable";
import {
  getDupSubMatchesAverage,
  getDupSubMatchesPerJournal,
  getDupSubMatchesPerPublisher,
  showErrorToast,
} from "../../../helpers";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import {
  matchesPerJournalColumns,
  matchesPerPublisherColumns,
} from "../columns";

const pills = {
  perPublisher: "perPublisher",
  perJournal: "perJournal",
};

const MatchReport = () => {
  const [currentPill, setCurrentPill] = useState(pills.perPublisher);

  const [isLoading, setIsLoading] = useState(false);

  const [matchStatistics, setMatchStatistics] = useState(null);
  const [matchesPerPublisher, setMatchesPerPublisher] = useState([]);
  const [matchesPerJournal, setMatchesPerJournal] = useState([]);

  const [timeRangeOption, setTimeRangeOption] = useState(
    timeRangeOptions.last_7_days.value
  );

  const handleBasicClick = (value) => {
    setCurrentPill(value);
  };

  const mapPerJournalResponseData = (response) => {
    const output = [];

    response?.forEach((publisher) => {
      output.push({
        publisherName: publisher.publisher_name,
      });

      publisher?.journals?.forEach((journal) => {
        output.push({
          journalName: journal?.journal,
          matched: journal?.matched,
          matches: journal?.matches,
          matching: journal?.matching,
          totalSubmissions: journal?.totalSubmissions,
          totalUniqueSubmissions: journal?.totalUniqueSubmissions,
        });
      });
    });

    return output;
  };

  useEffect(() => {
    const fetchData = async (timeRangeOption) => {
      try {
        setIsLoading(true);
        const [
          matchesAverageResult,
          matchesPerPublisherResult,
          matchesPerJournalResult,
        ] = await Promise.allSettled([
          getDupSubMatchesAverage(timeRangeOption),
          getDupSubMatchesPerPublisher(timeRangeOption),
          getDupSubMatchesPerJournal(timeRangeOption),
        ]);

        if (matchesAverageResult.status === "fulfilled") {
          setMatchStatistics(matchesAverageResult.value.data ?? null);
        } else {
          showErrorToast();
          console.error(matchesAverageResult.reason);
        }

        if (matchesPerPublisherResult.status === "fulfilled") {
          setMatchesPerPublisher(matchesPerPublisherResult.value.data ?? []);
        } else {
          showErrorToast();
          console.error(matchesPerPublisherResult.reason);
        }

        if (matchesPerJournalResult.status === "fulfilled") {
          const mappedData = mapPerJournalResponseData(
            matchesPerJournalResult.value.data ?? []
          );
          setMatchesPerJournal(mappedData);
        } else {
          showErrorToast();
          console.error(matchesPerJournalResult.reason);
        }
      } catch (error) {
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(timeRangeOption);
  }, [timeRangeOption]);

  return (
    <SpinnerOverlay active={isLoading}>
      <div className="row my-2 justify-content-end">
        <MDBCol lg={3} md={6} sm={6}>
          <TimeRangeSelect setTimeRangeOption={setTimeRangeOption} />
        </MDBCol>
      </div>
      <MDBCard className="mb-3">
        <MDBCardHeader>
          <MDBCardTitle className="text-center mt-2">
            Match Statistics
          </MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBCardText>
            <MDBRow>
              <MDBCol>
                <strong>Total # matches:</strong>{" "}
                {matchStatistics?.totalMatches}
              </MDBCol>
              <MDBCol>
                <strong>Total # submissions:</strong>{" "}
                {matchStatistics?.totalSubmissions}
              </MDBCol>
              <MDBCol>
                <strong>Total # unique submissions:</strong>{" "}
                {matchStatistics?.totalUniqueSubmissions}
              </MDBCol>
            </MDBRow>
          </MDBCardText>
          <MDBCardText>
            <strong>Average # matched manuscripts:</strong>{" "}
            {matchStatistics?.averageMatches}
          </MDBCardText>
          <MDBCardText>
            <strong>Average time between submissions:</strong>
            {matchStatistics?.averageTimeDiff || "N/A"}
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>

      <MDBRow>
        <MDBCol size="2">
          <MDBTabs pills className="flex-column text-center">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick(pills.perPublisher)}
                active={currentPill === pills.perPublisher}
              >
                Per Publisher
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick(pills.perJournal)}
                active={currentPill === pills.perJournal}
              >
                Per Journal
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCol>
        <MDBCol>
          <MDBTabsContent className="mb-10">
            <MDBTabsPane show={currentPill === pills.perPublisher}>
              <DataTable
                rows={matchesPerPublisher}
                columns={matchesPerPublisherColumns}
              />
            </MDBTabsPane>
            <MDBTabsPane show={currentPill === pills.perJournal}>
              <DataTable
                rows={matchesPerJournal}
                columns={matchesPerJournalColumns}
              />
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBCol>
      </MDBRow>
    </SpinnerOverlay>
  );
};

export default MatchReport;
