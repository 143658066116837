export const columns = [
  { label: "Publisher", field: "publisherName" },
  { label: "Profile", field: "profileName" },

  {
    label: "On-Demand Screening",
    field: "PMChk",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px",
  },
  {
    label: "Ambient Screening",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    field: "AMBScr",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px",
  },
  {
    label: "Watchlist",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    field: "WTLst",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px",
  },

  { label: "PubPeer hits", field: "FOCRat", width: "1px" },
  { label: "Papermill similarity", field: "CSStat", width: "1px" },
  {
    label: "Cactus Integrity Checks",
    field: "dummy",
    headerBackgroundColor: "rgba(0, 0, 0, 0.10)",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px"
  },
  {
    label: "Author affiliation relevance",
    field: "cactusTool.authorAffiliationRelevance",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px"
  },
  {
    label: "Author geographical distribution",
    field: "cactusTool.authorGeographicalDistribution",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px"
  },
  {
    label: "Age of reference",
    field: "cactusTool.ageOfReference",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px"
  },
  {
    label: "Reference citation",
    field: "cactusTool.referenceCitation",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px"
  },
  {
    label: "Citation cartel",
    field: "cactusTool.citationCartel",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px"
  },
  {
    label: "Self citation",
    field: "cactusTool.selfCitation",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px"
  },
  {
    label: "Unrelated references",
    field: "cactusTool.unrelatedReferences",
    headerBackgroundColor: "rgba(0, 0, 0, 0.05)",
    backgroundColor: "rgba(0, 0, 0, 0.025)",
    width: "1px"
  },
  { label: "Retraction Watch", field: "RWtch", width: "1px" },
  { label: "DOI analysis", field: "CRRF", width: "1px" },
  { label: "Tortured phrases", field: "TPHrs", width: "1px" },
  { label: "Unnatural text", field: "MLDet", width: "1px" },
  { label: "GenAI response", field: "genAIResponse", width: "1px" },
  { label: "Word file metadata", field: "wordFileProperties", width: "1px" },
  { label: "Metadata screening", field: "OPScr", width: "1px" },
  { label: "Fake affiliation names", field: "SSPan", width: "1px" },
  { label: "Fake names or e-mail addresses", field: "SSPidd", width: "1px" },
  { label: "Fake e-mail domains", field: "SSPfd", width: "1px" },
  { label: "Disposable e-mail domains", field: "SSPdd", width: "1px" },
  { label: "Bad actors", field: "SSPba", width: "1px" },
  { label: "Suspect articles", field: "SSPsa", width: "1px" },
  { label: "Manuscripts offered for sale", field: "SSPmfs", width: "1px" },
  { label: "Metadata suspects", field: "SSMeta", width: "1px" },
];
