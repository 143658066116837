export const submissionsInCorpusColumns = [
  {
    label: "Publisher",
    field: "publisherName",
    width: 250,
  },
  {
    label: "Submissions",
    field: "allSubmissions",
    width: 50,
  },
  {
    label: "Matches",
    field: "matchedSubmissions",
    width: 50,
  },
];
export const apiRequestsColumns = [
  {
    label: "Publisher",
    field: "publisherName",
    width: 250,
  },
  {
    label: "Received submissions",
    field: "receivedSubmissions",
    width: 50,
  },
  {
    label: "Submitted",
    field: "submitted",
    width: 50,
  },
  {
    label: "Received removals",
    field: "receivedRemovals",
    width: 50,
  },
  {
    label: "Removed",
    field: "removed",
    width: 50,
  },
  {
    label: "Matches",
    field: "numberOfMatches",
    width: 50,
  },
  {
    label: "Match %",
    field: "matchPercentage",
    width: 50,
  },
];
export const recentActivityColumns = [
  {
    label: "Timestamp",
    field: "timestamp",
    width: 120,
  },
  {
    label: "Publisher",
    field: "publisherName",
    width: 150,
  },
  {
    label: "Event",
    field: "actionType",
    width: 50,
  },
  {
    label: "Manuscript ID",
    field: "manuscriptId",
    width: 50,
  },
];

export const journalsColumns = [
  {
    label: "Publisher",
    field: "publisher",
  },
  {
    label: "# journals",
    field: "journalsCount",
  },
  {
    label: "Journal",
    field: "journalName",
  },
  {
    label: "# submissions",
    field: "submissionsCount",
  },
];

export const matchesPerPublisherColumns = [
  {
    label: "Publisher",
    field: "publisherName",
  },
  {
    label: "Matches",
    field: "matches",
  },
  {
    label: "Matching",
    field: "matching",
  },
  {
    label: "Matched",
    field: "matched",
  },
  {
    label: "Total confirmed",
    field: "totalConfirmed",
  },
  {
    label: "Total papermill suspected",
    field: "totalPapermillSuspected",
  },
  {
    label: "Total false positive",
    field: "totalFalsePositive",
  },
  {
    label: "Total hidden",
    field: "totalHidden",
  },
  {
    label: "Total Submissions",
    field: "totalSubmissions",
  },
  {
    label: "Unique submissions",
    field: "totalUniqueSubmissions",
  },
];

export const matchesPerJournalColumns = [
  {
    label: "Publisher",
    field: "publisherName",
  },
  {
    label: "Journal",
    field: "journalName",
  },
  {
    label: "Matches",
    field: "matches",
  },
  {
    label: "Matching",
    field: "matching",
  },
  {
    label: "Matched",
    field: "matched",
  },
  {
    label: "Total confirmed",
    field: "totalConfirmed",
  },
  {
    label: "Total papermill suspected",
    field: "totalPapermillSuspected",
  },
  {
    label: "Total false positive",
    field: "totalFalsePositive",
  },
  {
    label: "Total hidden",
    field: "totalHidden",
  },
  {
    label: "Total Submissions",
    field: "totalSubmissions",
  },
  {
    label: "Unique submissions",
    field: "totalUniqueSubmissions",
  },
];
