import { useState, useCallback } from 'react';

export const useDialogControl = (onConfirm) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [id, setId] = useState(null);

  const openDialog = useCallback((id) => {
    setId(id);
    setIsDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
    setId(null);
  }, []);

  const handleConfirm = useCallback(async () => {
    if (id && onConfirm) {
      await onConfirm(id);
      closeDialog();
    }
  }, [id, onConfirm, closeDialog]);

  return {
    isDialogOpen,
    openDialog,
    closeDialog,
    handleConfirm
  };
};
