import React from "react";
import { MDBInput, MDBCol, MDBRow, MDBTypography } from "mdb-react-ui-kit";

const PublisherForm = ({ values, onChange, errors }) => {
  return (
    <>
      <MDBRow className="mb-4">
        <MDBCol md={6}>
          <MDBTypography variant="h5" className="mb-3">
            Publisher
          </MDBTypography>
          <MDBInput
            label="Publisher name"
            id="name"
            name="name"
            type="text"
            onChange={onChange}
            value={values.name}
          />
          {errors?.name && (
            <div className="text-danger">{errors.name}</div>
          )}
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default PublisherForm;
