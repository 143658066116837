import React, { useState } from "react";
import {
  MDBContainer,
  MDBTabsPane,
  MDBTabsContent,
  MDBRow,
  MDBCol,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from "mdb-react-ui-kit";
import SubjectListTab from "./tabs/SubjectListTab";
import JournalListTab from "./tabs/JournalListTab";

const tabs = {
  journalList: "journalList",
  subjectList: "subjectList",
};

const MonitorJournals = () => {
  const [currentTab, setCurrentTab] = useState(tabs.journalList);

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol>
          <MDBTabs className="mb-3">
            <MDBTabsItem onClick={() => setCurrentTab(tabs.journalList)}>
              <MDBTabsLink active={currentTab === tabs.journalList}>
                Journal list
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.subjectList)}>
              <MDBTabsLink active={currentTab === tabs.subjectList}>
                Subject list
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCol>
      </MDBRow>
      <MDBTabsContent>
        <MDBTabsPane show={currentTab === tabs.journalList}>
          <JournalListTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.subjectList}>
          <SubjectListTab />
        </MDBTabsPane>
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default MonitorJournals;
