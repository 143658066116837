import { axiosInstance } from "./axiosSetup";

export const getProfiles = (startrow = 1, maxrows = 10, search = '') => {
    const searchQuery = search.length > 0 ? `&profile_name=${search}` : ''
    return axiosInstance.get(`/admin/profile/list?start_row=${startrow}&max_rows=${maxrows}${searchQuery}`);
};

export const getProfile = (profileId) => {
    return axiosInstance.get(`/admin/publisher/extract/profile/${profileId}`)
}

export const createProfile = (profileData) => {
    return axiosInstance.post(`/admin/profile`, profileData);
}

export const updateProfile = (profileId, profileData) => {
    return axiosInstance.patch(`/admin/profile/${profileId}`, profileData);
}

export const deleteProfile = (profileId) => {
    return axiosInstance.delete(`/admin/profile/${profileId}`);
}