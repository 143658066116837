import React, { useState } from "react";
import LoginForm from "../../components/LoginForm";
import UserDeviceForm from "../../components/UserDeviceForm";

const Login = (props) => {
    const [tab, setTab] = useState("login");
    const [authResponse, setAuthResponse] = useState(null);

    return (
        <>
            {
                tab === "login"
                    ? <LoginForm setAuthResponse={setAuthResponse} setTab={setTab} {...props} />
                    : <UserDeviceForm authResponse={authResponse} setTab={setTab} {...props} />
            }
        </>
    );
};

export default Login;