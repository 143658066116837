import React, { useState } from "react";
import {
  MDBContainer,
  MDBTabsPane,
  MDBTabsContent,
  MDBRow,
  MDBCol,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from "mdb-react-ui-kit";
import RecentActivityTab from "./tabs/RecentActivityTab";
import ApiRequestsTab from "./tabs/ApiRequestsTab";
import SubmissionsInCorpusTab from "./tabs/SubmissionsInCorpusTab";
import JournalsTab from "./tabs/JournalsTab";
import ChartTab from "./tabs/ChartTab";
import MatchReport from "./tabs/MatchReport";

const tabs = {
  submissionsInCorpus: "submissionsInCorpus",
  apiRequests: "apiRequests",
  recentActivity: "recentActivity",
  journals: "journals",
  chart: "chart",
  matchReport: "matchReport",
};

const DuplicateSubmissions = () => {
  const [currentTab, setCurrentTab] = useState(tabs.submissionsInCorpus);

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol>
          <MDBTabs className="mb-3">
            <MDBTabsItem
              onClick={() => setCurrentTab(tabs.submissionsInCorpus)}
            >
              <MDBTabsLink active={currentTab === tabs.submissionsInCorpus}>
                In corpus
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.journals)}>
              <MDBTabsLink active={currentTab === tabs.journals}>
                Journals
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.apiRequests)}>
              <MDBTabsLink active={currentTab === tabs.apiRequests}>
                Requests
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.chart)}>
              <MDBTabsLink active={currentTab === tabs.chart}>
                Chart view
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.matchReport)}>
              <MDBTabsLink active={currentTab === tabs.matchReport}>
                Matches
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem onClick={() => setCurrentTab(tabs.recentActivity)}>
              <MDBTabsLink active={currentTab === tabs.recentActivity}>
                Recent activity
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCol>
      </MDBRow>
      <MDBTabsContent>
        <MDBTabsPane show={currentTab === tabs.submissionsInCorpus}>
          <SubmissionsInCorpusTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.journals}>
          <JournalsTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.apiRequests}>
          <ApiRequestsTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.recentActivity}>
          <RecentActivityTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.chart}>
          <ChartTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.matchReport}>
          <MatchReport />
        </MDBTabsPane>
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default DuplicateSubmissions;
