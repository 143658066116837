export const columns = [
  {
    label: "ID",
    field: "id",
    width: 250,
  },
  {
    label: "Publisher",
    field: "publisher_name",
  },
  {
    label: "Cactus publisher",
    field: "publisher",
  },
  {
    label: "Cactus journal",
    field: "journal",
  },
  {
    label: "",
    field: "editAction",
  },
  {
    label: "",
    field: "deleteAction",
  },
];
