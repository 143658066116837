import * as XLSX from "xlsx";

export const exportToExcel = (data, fileName) => {
  const flattenedData = [];

  data.forEach((item) => {
    const flattenedRow = {
      "Publisher Name": item.publisher,
      "Journals Count": item.journalsCount,
      "Journal Name": item.journalName,
      Subject: item.subject,
    };
    flattenedData.push(flattenedRow);
  });

  const worksheet = XLSX.utils.json_to_sheet(flattenedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, fileName);
};
