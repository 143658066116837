import React, { useMemo, useState } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBTooltip,
} from "mdb-react-ui-kit";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import DataTable from "../../components/table/DataTable";
import Pagination from "../../components/Pagination/Pagination";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import SearchInput from "../../components/SearchInput/SearchInput";
import ProfileEditModal from "./components/ProfileEditModal";
import useProfiles from "./hooks/useProfiles";

export const columns = [
  {
    label: "ID",
    field: "id",
    width: 250,
  },
  {
    label: "Profile name",
    field: "name",
  },
  {
    labeL: "",
    field: "editAction",
    width: 20
  },
];

const Profiles = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const { profilesData, loading, error, pageCount, searchTerm, setSearchTerm, fetchData } = useProfiles(currentPage);

  const [profileToEditId, setProfileToEditId] = useState(null);

  const handleSearchFetch = async () => {
    if (currentPage === 0) {
      fetchData();
    }
    else {
      setCurrentPage(0)
    }
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const profilesDataWithActions = useMemo(() => profilesData?.map(profile => ({
    ...profile,
    editAction: (
      <MDBTooltip tag="div" title="Edit Profile" placement="top">
        <MDBBtn
          color="secondary"
          floating
          onClick={() => setProfileToEditId(profile.id)}
        >
          <MDBIcon fas icon="edit" />
        </MDBBtn>
      </MDBTooltip>
    )
  })), [profilesData]);

  return (
    <SpinnerOverlay active={loading}>
      <ProfileEditModal
        itemId={profileToEditId}
        setIsOpen={setProfileToEditId}
        onSuccessSubmit={fetchData}
      />
      <MDBRow className="mb-2">
        <MDBCol sm={8} md={6} lg={4}>
          <SearchInput placeholder="Search by profile name..." value={searchTerm} setValue={setSearchTerm} onSubmit={handleSearchFetch} />
        </MDBCol>
      </MDBRow>
      {error && <ErrorMessage />}
      {!error && profilesData && (
        <>
          <DataTable rows={profilesDataWithActions} columns={columns} />
          <MDBRow>
            <MDBCol className="mt-2">
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            </MDBCol>
          </MDBRow>
        </>
      )}
    </SpinnerOverlay>
  );
};

export default Profiles;
