import React, { useState } from "react";
import { MDBBtn, MDBCol, MDBIcon, MDBRow, MDBTooltip } from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";
import Pagination from "../../../components/Pagination/Pagination";
import DataTable from "../../../components/table/DataTable";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import Dialog from "../../../components/Dialog/Dialog";
import PublisherProfileCreateModal from "./components/PublisherProfileCreateModal";
import PublisherProfileEditModal from "./components/PublisherProfileEditModal";
import { usePublisherProfiles } from "./hooks/usePublisherProfiles";

export const columns = [
  {
    label: "ID",
    field: "id",
    width: 250,
  },
  {
    label: "Profile name",
    field: "profile_name",
  },

  {
    label: "",
    field: "editAction",
    width: 20,
  },
  {
    label: "",
    field: "deleteAction",
    width: 20,
  },
];

const PublisherProfilesTab = () => {
  let { id } = useParams();

  const [profileToEditId, setProfileToEditId] = useState(null);
  const [profileToDeleteId, setProfileToDeleteId] = useState(null);
  const [isCreateProfileModalOpen, setIsCreateProfileModalOpen] =
    useState(false);

  const {
    profilesData,
    loading,
    error,
    currentPage,
    pageCount,
    setCurrentPage,
    handleDeleteProfile,
    fetchData,
  } = usePublisherProfiles(id);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleDeleteProfileConfirm = async () => {
    await handleDeleteProfile(profileToDeleteId);
    setProfileToDeleteId(null);
  };
  const handleCreatePublisherProfileClick = () => {
    setIsCreateProfileModalOpen(true);
  };

  const profilesDataWithActions = profilesData?.map(profile => ({
    ...profile,
    editAction: (
      <MDBTooltip tag="div" title="Edit Profile" placement="top">
        <MDBBtn
          color="secondary"
          floating
          onClick={() => setProfileToEditId(profile.id)}
        >
          <MDBIcon fas icon="edit" />
        </MDBBtn>
      </MDBTooltip>
    ),
    deleteAction: (
      <MDBTooltip tag="div" title="Remove Profile" placement="top">
        <MDBBtn
          color="danger"
          floating
          onClick={() => setProfileToDeleteId(profile.id)}
        >
          <MDBIcon far icon="trash-alt" />
        </MDBBtn>
      </MDBTooltip>
    )
  }));

  return (
    <SpinnerOverlay active={loading}>
      <Dialog
        onConfirm={async () => await handleDeleteProfileConfirm(profileToDeleteId)}
        isOpen={!!profileToDeleteId}
        setIsOpen={setProfileToDeleteId}
        dialogText={"Are you sure you want to delete this profile?"}
      />
      <PublisherProfileCreateModal
        isOpen={isCreateProfileModalOpen}
        onClose={() => setIsCreateProfileModalOpen(false)}
        onSuccessSubmit={fetchData}
      />
      <PublisherProfileEditModal
        itemId={profileToEditId}
        onClose={() => setProfileToEditId(null)}
        onSuccessSubmit={fetchData}
      />

      {error && <ErrorMessage />}
      {!error && profilesData && (
        <>
          <MDBRow className="mb-2">
            <MDBCol className="text-end">
              <MDBBtn
                color="primary"
                size="md"
                onClick={handleCreatePublisherProfileClick}
                disabled={loading}
              >
                <MDBIcon icon="plus-circle" className="me-2" />
                New profile
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <DataTable rows={profilesDataWithActions} columns={columns} />
          <MDBRow>
            <MDBCol className="mt-2">
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            </MDBCol>
          </MDBRow>
        </>
      )}
    </SpinnerOverlay>
  );
};

export default PublisherProfilesTab;
