import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MDBBtn, MDBCol, MDBInputGroup, MDBRow } from 'mdb-react-ui-kit';
import NoContentMessage from '../../../components/NoContentMessage/NoContentMessage';
import SpinnerOverlay from '../../../components/SpinnerOverlay/SpinnerOverlay';
import Pagination from '../../../components/Pagination/Pagination';
import DataTable from '../../../components/table/DataTable';
import { showErrorToast, getCheckerList } from '../../../helpers';
import { itemsPerPage } from '../../../constants';
import { screenTabColumns } from '../columns';
import DetailedViewModal from '../components/DetailedViewModal';

const CheckerListTab = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [checkerData, setCheckerData] = useState(null);
  const [searchState, setSearchState] = useState('');

  const [detailedViewItem, setDetailedViewItem] = useState(null);

  const isInitialRender = useRef(true);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const fetchCheckerList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getCheckerList(
        currentPage * itemsPerPage + 1,
        itemsPerPage,
        searchState
      );
      const { suspects: data, total } = response.data;
      setCheckerData(data);
      setPageCount(Math.ceil(total / itemsPerPage));
    } catch {
      showErrorToast();
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchState]);

  const handleSearch = async () => {
    setCurrentPage(0);
    await fetchCheckerList(1);
    isInitialRender.current = false;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && searchState.length >= 3) {
      handleSearch();
    }
  };

  useEffect(() => {
    if (!isInitialRender.current) {
      fetchCheckerList();
    }
  }, [currentPage]);

  const checkerDataWithActions =
    checkerData?.map((item) => {
      return {
        ...item,
        hit: item.value,
        clickEvent: () => setDetailedViewItem(item),
      };
    }) ?? [];

  return (
    <>
      <DetailedViewModal
        item={detailedViewItem}
        onClose={() => setDetailedViewItem(null)}
      />
      <SpinnerOverlay active={loading}>
        <div className="table_header table-primary d-flex px-3 py-2">
          <MDBCol sm={8} md={6} lg={4}>
            <MDBInputGroup className="p-1">
              <input
                className="form-control"
                placeholder="Minimum three letters to search..."
                type="text"
                onChange={(e) => setSearchState(e.target.value)}
                value={searchState}
                onKeyDown={handleKeyPress}
              />
              <MDBBtn disabled={searchState?.length < 3} onClick={handleSearch}>
                Search
              </MDBBtn>
            </MDBInputGroup>
          </MDBCol>
        </div>
        {checkerData?.length > 0 && (
          <>
            <DataTable
              columns={screenTabColumns}
              rows={checkerDataWithActions}
            />
            <MDBRow>
              <MDBCol className="mt-2">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageCount={pageCount}
                  currentPage={currentPage}
                />
              </MDBCol>
            </MDBRow>
          </>
        )}
        {checkerData?.length === 0 && <NoContentMessage message={'No data'} />}
      </SpinnerOverlay>
    </>
  );
};

export default CheckerListTab;
