import { useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { monthlyTabColumns } from "../columns";
import {
  getAmbientScreeningMonthly,
  getOnAmbientScreeningStatisticsPerWeek,
  showErrorToast,
} from "../../../helpers";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import { BarChart } from "../../../components";

const MonthlyTab = () => {
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [monthlyData, weeklyStats] = await Promise.all([
          getAmbientScreeningMonthly(),
          getOnAmbientScreeningStatisticsPerWeek(),
        ]);

        setData(monthlyData.data);
        setChartData(weeklyStats.data);
      } catch (error) {
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const keys = Array.from(new Set(chartData.flatMap(Object.keys))).filter(
    (key) => key !== "week"
  );

  return (
    <SpinnerOverlay active={isLoading}>
      <BarChart keys={keys} data={chartData} />
      <DataTable columns={monthlyTabColumns} rows={data} />
    </SpinnerOverlay>
  );
};

export default MonthlyTab;
