import React from 'react';
import {
  MDBCol,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import Select from 'react-select';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormModalWrapper } from '../../../components';
import { useCactusSettings } from '../hooks/useCactusSettings';

const validationSchema = Yup.object({
  publisher: Yup.string().required('Cactus publisher is required'),
  journal: Yup.string().required('Cactus journal is required'),
  publisherId: Yup.mixed()
    .required('Publisher is required')
    .test('is-object', 'Publisher is required', value => value !== null && value.value !== '')
});

const INITIAL_FORM_STATE = {
  publisher: '',
  journal: '',
  publisherId: null
};

const CactusSettingsModal = ({ record, publisherOptions, onClose, onSubmitSuccess }) => {
  const { createEntry, updateEntry } = useCactusSettings({ initialFetch: false });
  const isOpen = record !== undefined;
  const isEditMode = Boolean(record);
  const modalTitle = isEditMode ? 'Edit Entry' : 'Create Entry';

  const handleClose = () => {
    onClose();
    formik.resetForm();
    formik.setTouched({});
  };

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: isEditMode
      ? {
        publisher: record.publisher,
        journal: record.journal,
        publisherId: publisherOptions.find(option => option.value === record.publisher_id) || null
      }
      : INITIAL_FORM_STATE,
    onSubmit: async (values) => {
      try {
        const formattedValues = {
          publisher: values.publisher,
          journal: values.journal,
          publisherId: values.publisherId?.value
        };

        if (isEditMode) {
          await updateEntry(record.id, formattedValues);
        } else {
          await createEntry(formattedValues);
        }

        handleClose();
        await onSubmitSuccess();
      } catch (error) {
        console.error('Failed to submit form:', error);
      }
    }
  });

  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: '38px',
      borderColor: formik.errors.publisherId && formik.touched.publisherId ? '#dc3545' : base.borderColor,
      '&:hover': {
        borderColor: formik.errors.publisherId && formik.touched.publisherId ? '#dc3545' : base.borderColor
      }
    })
  };

  const renderSelectField = (fieldName, label, options) => (
    <MDBCol md={6} className='mb-3'>
      <Select
        id={fieldName}
        name={fieldName}
        options={options}
        value={formik.values[fieldName]}
        onChange={(option) => {
          formik.setFieldValue(fieldName, option);
          formik.setFieldTouched(fieldName, true, false);
        }}
        onBlur={() => formik.setFieldTouched(fieldName, true)}
        placeholder={`Select ${label.toLowerCase()}...`}
        isClearable
        styles={customStyles}
        className={
          formik.errors[fieldName] && formik.touched[fieldName] ? 'is-invalid' : ''
        }
      />
      {formik.touched[fieldName] && formik.errors[fieldName] && (
        <div className='text-danger mt-1'>{formik.errors[fieldName]}</div>
      )}
    </MDBCol>
  );

  const renderInputField = (fieldName, label) => (
    <MDBCol md={6} className='mb-3'>
      <MDBInput
        id={fieldName}
        label={label}
        {...formik.getFieldProps(fieldName)}
      />
      {formik.errors[fieldName] && formik.touched[fieldName] && (
        <div className='text-danger'>{formik.errors[fieldName]}</div>
      )}
    </MDBCol>
  );

  return (
    <FormModalWrapper
      title={modalTitle}
      isOpen={isOpen}
      isLoading={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleClose}
    >
      <MDBRow>
        {renderSelectField('publisherId', 'Publisher', publisherOptions)}
      </MDBRow>
      <MDBRow>
        {renderInputField('publisher', 'Cactus publisher')}
        {renderInputField('journal', 'Cactus journal')}
      </MDBRow>
    </FormModalWrapper>
  );
};

export default CactusSettingsModal;
