import { useEffect, useState } from "react";
import { MDBCol } from "mdb-react-ui-kit";
import DataTable from "../../../components/table/DataTable";
import { getDupSubRequests, showErrorToast } from "../../../helpers";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import { apiRequestsColumns } from "../columns";
import { timeRangeOptions, TimeRangeSelect } from "../../../components";

const ApiRequestsTab = () => {
  const [data, setData] = useState([]);
  const [timeRangeOption, setTimeRangeOption] = useState(
    timeRangeOptions.last_7_days.value
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getDupSubRequests(timeRangeOption);
        setData([
          ...data.statistics,
          { ...data?.totals, publisherName: "Totals" },
        ]);
      } catch (error) {
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [timeRangeOption]);

  const mappedData = data?.map((item) => ({
    ...item,
    matchPercentage: item?.submitted
      ? `${((item.numberOfMatches / item.submitted) * 100).toFixed(1)}%`
      : "",
  }));

  return (
    <SpinnerOverlay active={isLoading}>
      <div className="row my-2 justify-content-end">
        <MDBCol lg={3} md={6} sm={6}>
          <TimeRangeSelect setTimeRangeOption={setTimeRangeOption} />
        </MDBCol>
      </div>
      <DataTable columns={apiRequestsColumns} rows={mappedData} />
    </SpinnerOverlay>
  );
};

export default ApiRequestsTab;
