import { useCallback, useEffect, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { recentActivityColumns } from '../columns';
import { convertISOToReadableDate, getDupSubRecent, showErrorToast } from "../../../helpers";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import { MDBBtn } from "mdb-react-ui-kit";

const RecentActivityTab = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastEventTimestamp, setLastEventTimestamp] = useState('');

    const [hasMoreData, setHasMoreData] = useState(true);

    const initialLoadCount = 100;

    useEffect(() => {
        fetchData(initialLoadCount);
    }, []);

    const fetchData = async (limit, lastTimestamp = '') => {
        try {
            setIsLoading(true);
            const { data: fetchedData } = await getDupSubRecent(limit, lastTimestamp);
            const recentActivityRows = fetchedData?.lastEvents
                ? fetchedData?.lastEvents.map(item => ({
                    ...item,
                    timestamp: convertISOToReadableDate(item?.eventTimestamp),
                }))
                : [];
            setData(prevData => lastTimestamp === '' ? recentActivityRows : [...prevData, ...recentActivityRows]);

            if (recentActivityRows.length > 0) {
                setLastEventTimestamp(recentActivityRows[recentActivityRows.length - 1].eventTimestamp);
            }

            if (recentActivityRows.length < limit) {
                setHasMoreData(false);
            }
        } catch (error) {
            showErrorToast();
        } finally {
            setIsLoading(false);
        }
    };

    const loadMoreData = useCallback(async () => {
        await fetchData(initialLoadCount, lastEventTimestamp);
    }, [lastEventTimestamp]);

    return (
        <SpinnerOverlay active={isLoading}>
            <DataTable
                columns={recentActivityColumns}
                rows={data}
            />
            {!isLoading && hasMoreData && (
                <div className="d-flex justify-content-center mt-3">
                    <MDBBtn onClick={loadMoreData} disabled={isLoading}>
                        Load more data
                    </MDBBtn>
                </div>
            )}
        </SpinnerOverlay>
    );
};

export default RecentActivityTab;