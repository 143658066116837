import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import SpinnerOverlay from "../SpinnerOverlay/SpinnerOverlay";

const Dialog = ({
  isOpen,
  setIsOpen,
  onConfirm,
  dialogText,
  dialogTitle = "",
  showSpinner = true
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true);
      await onConfirm();
      handleClose();
    }
    finally {
      setIsSubmitting(false)
    }
  };

  const handleClose = () => setIsOpen(false);

  return (
    <MDBModal staticBackdrop show={isOpen} tabIndex="-1">
      <MDBModalDialog className="modal-md">
        <SpinnerOverlay active={isSubmitting && showSpinner}>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{dialogTitle}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={handleClose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="px-4">{dialogText}</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={handleClose}>
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" onClick={handleConfirm}>
                Confirm
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </SpinnerOverlay>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default Dialog;
