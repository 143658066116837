import React, { useEffect, useState } from "react";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import { getDupSubStatisticsPerWeek } from "../../../helpers";
import { BarChart } from "../../../components";

const ChartTab = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await getDupSubStatisticsPerWeek();
        setData(data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const keys = Array.from(new Set(data.flatMap(Object.keys))).filter(
    (key) => key !== "week"
  );

  return (
    <SpinnerOverlay active={isLoading}>
      <BarChart keys={keys} data={data} />
    </SpinnerOverlay>
  );
};

export default ChartTab;
