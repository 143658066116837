import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  getProfile,
  showErrorToast,
  showSuccessToast,
  updateProfile,
} from '../../../helpers';
import { FormModalWrapper } from '../../../components';
import PublisherProfileForm from '../../publishers/components/PublisherProfileForm';
import { useCactusSettings } from '../../cactus-settings/hooks';

const profileValidationSchema = Yup.object({
  profile_name: Yup.string().required('Profile name is required'),
});

const ProfileEditModal = ({ setIsOpen, itemId, onSuccessSubmit }) => {
  const isOpen = !!itemId;
  const [isLoading, setIsLoading] = useState(false);
  const [hideCactusSection, setHideCactusSection] = useState(true);
  const {
    settings: cactusSettings,
    fetchEntries: fetchCactusSettingsEntries
  } = useCactusSettings({ initialFetch: false });

  const initialValues = {
    profile_name: '',
    components: {},
    service_keys: {
      clearSkiesAPIKey: null,
      pubPeerAPIKey: null,
    },
    profile_id: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: profileValidationSchema,
    onSubmit: async (values) => {
      try {
        const profileToSubmit = {
          profile_name: values.profile_name,
          components: values.components,
          service_keys: values.service_keys,
        };
        await updateProfile(values.profile_id, profileToSubmit);
        showSuccessToast();
        await onSuccessSubmit();
        handleCloseModal();
      } catch (error) {
        showErrorToast();
      }
    },
  });

  const handleCloseModal = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getProfile(itemId);
        formik.setValues(data);

        await fetchCactusSettingsEntries();
      } catch (error) {
        handleCloseModal();
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    if (itemId) {
      fetchData();
    }
  }, [itemId]);

  useEffect(() => {
    if (formik.values?.publisher_id && cactusSettings.length) {
      const hasCactusEntry = cactusSettings.some(
        setting => setting.publisher_id === formik.values.publisher_id
      );
      setHideCactusSection(!hasCactusEntry);
    }
  }, [formik.values?.publisher_id, cactusSettings]);

  if (!formik.values) {
    return null;
  }

  return (
    <FormModalWrapper
      title={'Edit profile'}
      isOpen={isOpen}
      isLoading={isLoading || formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <PublisherProfileForm
        values={formik.values}
        onChange={formik.setFieldValue}
        errors={formik.errors}
        disabledSections={{ cactusIntegrityChecks: hideCactusSection }}
      />
    </FormModalWrapper>
  );
};

export default ProfileEditModal;