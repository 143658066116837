export const CACTUS_SETTINGS_API = {
    fetchEntries: (start_row, max_rows, filter) => ({
        url: '/admin/settings/cactus/list',
        method: 'GET',
        params: { start_row, max_rows, filter }
    }),
    createEntry: () => ({
        url: '/admin/settings/cactus',
        method: 'POST'
    }),
    updateEntry: (entryId) => ({
        url: `/admin/settings/cactus/${entryId}`,
        method: 'PATCH'
    }),
    deleteEntry: (entryId) => ({
        url: `/admin/settings/cactus/${entryId}`,
        method: 'DELETE'
    })
};
