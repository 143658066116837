import { axiosInstance } from "./axiosSetup";

export const getUsers = (startrow = 1, maxrows = 8, search = '') => {
    const searchQuery = search.length > 0 ? `&email=${search}` : ''
    return axiosInstance.get(`/admin/user/list?start_row=${startrow}&max_rows=${maxrows}${searchQuery}`);
};

export const getUser = (id) => {
    return axiosInstance.get(`/admin/user/extract/${id}`)
}

export const updateUser = (id, data) => {
    return axiosInstance.patch(`/admin/user/${id}`, data)
}

export const deleteUser = (id) => {
    return axiosInstance.delete(`/admin/user/${id}`)
}

export const createUser = (user) => {
    return axiosInstance.post(`/admin/user`, user);
}