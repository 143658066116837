import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { FormModalWrapper } from '../../../../components';
import PublisherProfileForm from '../../../publishers/components/PublisherProfileForm';
import {
  createProfile,
  showErrorToast,
  showSuccessToast,
} from '../../../../helpers';
import { useCactusSettings } from '../../../cactus-settings/hooks';

const PublisherProfileCreateModal = ({ isOpen, onClose, onSuccessSubmit }) => {
  let { id: publisherId } = useParams();
  const [hideCactusSection, setHideCactusSection] = useState(true);
  const {
    settings: cactusSettings,
    fetchEntries: fetchCactusSettingsEntries
  } = useCactusSettings({ initialFetch: false });

  useEffect(() => {
    if (isOpen && publisherId) {
      fetchCactusSettingsEntries();
    }
  }, [publisherId, isOpen]);

  useEffect(() => {
    if (publisherId && cactusSettings.length) {
      const hasCactusEntry = cactusSettings.some(
        setting => setting.publisher_id === publisherId
      );
      setHideCactusSection(!hasCactusEntry);
    }
  }, [publisherId, cactusSettings]);

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };
  const profileValidationSchema = Yup.object({
    profile_name: Yup.string().required('Profile name is required'),
  });

  const formik = useFormik({
    validationSchema: profileValidationSchema,
    initialValues: {
      profile_name: '',
      components: {},
      service_keys: {
        clearSkiesAPIKey: null,
        pubPeerAPIKey: null,
      },
    },
    onSubmit: async (values) => {
      try {
        const newProfile = {
          ...values,
          publisher_id: publisherId,
        };
        await createProfile(newProfile);
        await onSuccessSubmit(formik.values);
        showSuccessToast();
        handleCloseModal();
      } catch (error) {
        showErrorToast();
      }
    },
  });

  return (
    <FormModalWrapper
      title={'Create profile'}
      isOpen={isOpen}
      isLoading={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <PublisherProfileForm
        values={formik.values}
        onChange={formik.setFieldValue}
        errors={formik.errors}
        disabledSections={{ cactusIntegrityChecks: hideCactusSection }}
      />
    </FormModalWrapper>
  );
};

export default PublisherProfileCreateModal;
