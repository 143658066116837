import React from "react";
import {
    MDBCol,
    MDBInput,
    MDBRow
} from "mdb-react-ui-kit";
import * as Yup from 'yup';
import { useFormik } from "formik";
import useKeys from "../hooks/useKeys";
import { FormModalWrapper } from "../../../components";

const keyValidationSchema = Yup.object({
    key_name: Yup.string().required('Key name is required'),
    key_path: Yup.string().required('Key path is required')
})

const KeyCreateModal = ({ onClose, isOpen, onSuccessSubmit }) => {
    const handleCloseModal = () => {
        onClose();
        formik.resetForm();
    }

    const { handleCreateKey } = useKeys({ doInitialFetch: false })

    const formik = useFormik({
        validationSchema: keyValidationSchema,
        initialValues: {
            key_name: '',
            key_path: ''
        },
        onSubmit: async (values) => {
            try {
                await handleCreateKey(values);
                handleCloseModal();
                await onSuccessSubmit();
            } catch (error) { }
        }
    })
    return (
        <FormModalWrapper
            title={'Create key'}
            isOpen={isOpen}
            isLoading={formik.isSubmitting}
            onSubmit={formik.handleSubmit}
            onClose={handleCloseModal}
        >
            <MDBRow className="mb-3">
                <MDBCol md={6}>
                    <MDBInput
                        id="key_name"
                        label="Key name"
                        {...formik.getFieldProps('key_name')}
                    />
                    {formik.errors.key_name ? (
                        <div className="text-danger">{formik.errors.key_name}</div>
                    ) : null}
                </MDBCol>
                <MDBCol md={6}>
                    <MDBInput
                        id="key_path"
                        label="Key path"
                        {...formik.getFieldProps('key_path')}
                    />
                    {formik.errors.key_path ? (
                        <div className="text-danger">{formik.errors.key_path}</div>
                    ) : null}
                </MDBCol>
            </MDBRow>
        </FormModalWrapper>
    );
};

export default KeyCreateModal;
