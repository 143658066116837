import { axiosInstance } from "./axiosSetup";

export const getKeys = (startrow = 1, maxrows = Number.MAX_SAFE_INTEGER, search = '') => {
    const searchQuery = search.length > 0 ? `&key_name=${search}` : ''
    return axiosInstance.get(`/admin/external-keys/list?start_row=${startrow}&max_rows=${maxrows}${searchQuery}`);
};

export const getKey = (id) => {
    return axiosInstance.get(`/admin/external-keys/extract/${id}`)
}

export const createKey = (data) => {
    return axiosInstance.post(`/admin/external-keys`, data)
}

export const updateKey = (id, data) => {
    return axiosInstance.patch(`/admin/external-keys/${id}`, data)
}

export const deleteKey = (id) => {
    return axiosInstance.delete(`/admin/external-keys/${id}`)
}