import React, { useEffect, useState } from "react";
import { getJournalSubjects } from "../../../helpers/journals";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import DataTable from "../../../components/table/DataTable";
import { ErrorMessage } from "formik";
import { journalSuspectsColumns } from "../columns";

const SubjectListTab = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data: response } = await getJournalSubjects();
        setData(response);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const showErrorMessage = !isLoading && error;
  const showData = !showErrorMessage && data?.length > 0;

  return (
    <SpinnerOverlay active={isLoading}>
      {showErrorMessage && <ErrorMessage />}
      {showData && <DataTable columns={journalSuspectsColumns} rows={data} />}
    </SpinnerOverlay>
  );
};

export default SubjectListTab;
