export const cognitoUsersColumns = [
    {
        label: 'User email',
        field: 'email',
    },
    {
        label: 'Publisher',
        field: 'publisherName',
    },
    {
        label: 'Last login date',
        field: 'lastLoginDate',
    },
    {
        label: 'Last update date',
        field: 'lastUpdateDate',
    }
]
